import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import {motion, AnimatePresence} from "framer-motion";

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";
import OfferGuide from "../../components/offer-guide/OfferGuide";

import categoryStyle from "./assets/Category.module.scss";

const CategoryTemplatePage = ({
  title,
  slug,
  description,
  collectionsList,
  guide
}) => {
  useEffect(() => {
    collectionsList.forEach(collection => {
      new Image().src =
        collection.node.dressSet.edges[0].node.dresspictureSet.edges[0].node.processedPhotoSharp.childImageSharp.fluid;
    });
    // eslint-disable-next-line
  }, []);

  const [selectedCollection, setSelectedCollection] = useState(collectionsList[0]);

  const onCollectionHover = name => {
    setSelectedCollection(
      collectionsList.find(collection => collection.node.name === name)
    );
  };

  return (
    <Layout title={title}>
      <section name="category" className={categoryStyle["category"]}>
        <div className={categoryStyle["category_container"]}>
          <div className={categoryStyle["category_title"]}>
            <h1>{title}</h1>
          </div>
          <div className={categoryStyle["category_content"]}>
            {description && (
              <div className={categoryStyle["category_description"]}>
                <p>{description}</p>
              </div>
            )}
            <div className={categoryStyle["category_list"]}>
              {collectionsList &&
                collectionsList.map(collection => (
                  <div
                    key={collection.node.id}
                    className={categoryStyle["category_list_item"]}
                  >
                    <StyledLink
                      to={`/${slug}/${collection.node.slug}`}
                      onMouseEnter={() =>
                        onCollectionHover(collection.node.name)
                      }
                    >
                      {collection.node.name}
                    </StyledLink>
                  </div>
                ))}
            </div>
          </div>
          <div className={categoryStyle["category_image"]}>
            <Img
              className={categoryStyle["category_image_placeholder"]}
              key={selectedCollection.node.dressSet.slug}
              fluid={
                selectedCollection.node.dressSet.edges[0].node.dresspictureSet
                  .edges[0].node.processedPhotoSharp.childImageSharp.fluid
              }
              alt={selectedCollection.node.dressSet.name}
            />
            <AnimatePresence>
              <motion.div
                className={categoryStyle["category_image_motion"]}
                key={selectedCollection.name}
                initial={{opacity: 0, transition: {duration: 0.5}}}
                animate={{opacity: 1, transition: {duration: 0.5}}}
                exit={{
                  opacity: 0,
                  transition: {duration: 0.5, delay: 0.33}
                }}
              >
                {/* @TODO: Currently not working, possibly because of how gatsby-image handles source change */}
                <Img
                  key={selectedCollection.node.dressSet.slug}
                  fluid={
                    selectedCollection.node.dressSet.edges[0].node
                      .dresspictureSet.edges[0].node.processedPhotoSharp
                      .childImageSharp.fluid
                  }
                  alt={selectedCollection.node.dressSet.name}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </section>

      {guide && <OfferGuide />}
    </Layout>
  );
};

CategoryTemplatePage.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  collectionsList: PropTypes.array.isRequired,
  guide: PropTypes.bool
}

export default CategoryTemplatePage;