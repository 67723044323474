import React from "react";

import CategoryTemplatePage from "../../templates/category/Category";

import svatebniSatyCollections from "../../queries/svatebniSatyCollections";

const SvatebniSatyPage = () => {
  const svatebniSatyCollectionsQuery = svatebniSatyCollections();
  const collectionsList =
    svatebniSatyCollectionsQuery.django.allCategories.edges[0].node
      .collectionSet.edges;

  return (
    <CategoryTemplatePage
      title="Svatební šaty"
      slug="svatebni-saty"
      description=""
      collectionsList={collectionsList}
      guide={true}
    />
  );
};

export default SvatebniSatyPage;
